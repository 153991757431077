<template>
  <div>
    <v-card-text>
      <v-card outlined class="white pa-2">
        <v-progress-linear indeterminate color="primary" v-if="processing1"></v-progress-linear>
        <v-subheader style="font-size: 16px !important">Comisiones por Incentivos {{ currentUserCapitalLetter
          }}</v-subheader>

        <div ref="amchart_incentivo_individual" style="height: 90vh"></div>
        <v-alert dense type="warning" v-show="!processing1 && isChartIncentivoIndividualEmpty">No existe información
          para esta gráfica</v-alert>
      </v-card>
      <br />
      <v-card outlined class="white pa-2" v-if="currentUser.perfil === 'Gerente'">
        <br />
        <v-progress-linear indeterminate color="primary" v-if="processing2"></v-progress-linear>
        <v-subheader style="font-size: 16px !important">Comisiones por Incentivos</v-subheader>

        <div ref="amchart_incentivo_sum" style="height: 90vh;min-height: 90vh;"></div>
        <v-alert dense type="warning" v-show="!processing2 && isChartIncentivoSumEmpty">No existe información para esta
          gráfica</v-alert>
      </v-card>
      <br />
      <v-card outlined class="white pa-2" v-if="currentUser.perfil === 'Gerente'">
        <br />
        <v-progress-linear indeterminate color="primary" v-if="processing2"></v-progress-linear>
        <v-subheader style="font-size: 16px !important">Incentivos Promedios</v-subheader>

        <div ref="amchart_incentivo" style="height: 50vh"></div>
        <v-alert dense type="warning" v-show="!processing2 && isChartIncentivoEmpty">No existe información para esta
          gráfica</v-alert>
      </v-card>
      <br />
      <v-card outlined class="white pa-2" v-if="currentUser.perfil === 'Gerente'">

        <br />
        <v-progress-linear indeterminate color="primary" v-if="processingYtd"></v-progress-linear>
        <br />
        <br />
        <v-subheader style="font-size: 16px !important">YTD por incentivo &nbsp;&nbsp;<div style="width: 10%"><v-select
              :items="chartGroupingTypeData" v-model="chartGroupingType" item-text="Nombre" item-value="Id"
              label="Agrupado por" append-icon="keyboard_arrow_down" persistent-hint></v-select></div></v-subheader>
        <div ref="ytd_incentivo" style="height: 50vh"></div>
        <v-alert dense type="warning" v-show="!processingYtd && isChartYtdEmpty">No existe información para esta
          gráfica</v-alert>
      </v-card>
    </v-card-text>

    <v-fab-transition>
      <v-btn v-if="$vuetify.breakpoint.mobile" color="transparent" absolute top right fixed
        style="top: 120px; right: 0px" @click.native="drawerRight = !drawerRight">
        <v-icon>filter_alt</v-icon>
      </v-btn>
    </v-fab-transition>

    <v-navigation-drawer v-model="drawerRight" app clipped fixed right>
      <v-card-text>
        <div class="subtitle-1 py-5">Filtros</div>
        <v-divider></v-divider>
        <br />

        <div v-if="currentUser.perfil === 'Gerente'">

          <v-autocomplete label="Año" :items="listaAnios" v-model="selectedAnio" item-text="Nombre"
            item-value="Value" append-icon="keyboard_arrow_down" hint="Año"
            persistent-hint></v-autocomplete>

          <v-autocomplete label="Canales" :items="listaCanal" v-model="selectedCanales" item-text="Nombre"
            item-value="CanalId" append-icon="keyboard_arrow_down" hint="Dejar vacio para filtrar por todos"
            persistent-hint clearable multiple chips deletable-chips></v-autocomplete>


          <v-autocomplete label="Cortes" :items="listaCortes" v-model="selectedCortes" item-text="Nombre"
            item-value="CorteId" append-icon="keyboard_arrow_down" hint="Dejar vacio para filtrar por todos"
            persistent-hint clearable multiple chips deletable-chips></v-autocomplete>


          <v-autocomplete label="Segmentos" :items="listSegmento" v-model="selectedSegmento" item-text="Nombre"
            item-value="SegmentoID" append-icon="keyboard_arrow_down" hint="Dejar vacio para filtrar por todos"
            persistent-hint clearable multiple chips deletable-chips></v-autocomplete>
          <v-autocomplete label="Tiendas" :items="listTienda" v-model="selectedTienda" item-text="Nombre"
            item-value="CanalSucursalId" append-icon="keyboard_arrow_down" hint="Dejar vacio para filtrar por todos"
            persistent-hint clearable multiple chips deletable-chips></v-autocomplete>
          <v-select :items="listPuesto" v-model="selectedPuesto" item-text="Puesto" item-value="Puesto"
            hint="Grupo comisión" append-icon="keyboard_arrow_down" persistent-hint clearable></v-select>
          <v-select :key="listIncentivoKey" :items="listIncentivo" v-model="selectedIncentivo" multiple
            item-text="DisplayText" item-value="Value" hint="Debe seleccionar un incentivos" label="Incentivos"
            append-icon="keyboard_arrow_down" persistent-hint clearable></v-select>
          <v-autocomplete :items="filterEmpleado" v-model="selectedEmpleado" item-text="Nombre" item-value="EmpleadosId"
            label="Empleados" append-icon="keyboard_arrow_down" persistent-hint clearable multiple chips
            deletable-chips></v-autocomplete>

        </div>

        <v-autocomplete :items="tiposIncentivos" v-model="tipoIncentivo" item-text="nombre" item-value="tipoIncentivoId"
          label="Tipo Incentivo" append-icon="keyboard_arrow_down"></v-autocomplete>

          

        <v-text-field v-model="mesesAnteriores" label="Meses Anteriores" required autofocus></v-text-field>

        <br />
        <div class="mb-5 text-right">
          <v-btn block class="ma-1" depressed color="primary" @click="generate">
            <v-icon left>search</v-icon>Buscar
            <span slot="loader">Buscando...</span>
          </v-btn>
        </div>
      </v-card-text>
    </v-navigation-drawer>
  </div>
</template>

<script>
import api from "@/api/dashboards";
import apiEmpleado from "@/api/empleados";
import { mapActions } from "vuex";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themesAnimated from "@amcharts/amcharts4/themes/material";
import helper from "@/filters/helper";

am4core.useTheme(am4themesAnimated);

export default {
  data() {
    return {
      drawerRight: true,
      filter: true,
      processing1: false,
      processing2: false,
      processingYtd: false,
      tiposIncentivos: [{ tipoIncentivoId: 0, nombre: 'Todos' }, { tipoIncentivoId: 1, nombre: 'Pago' }, { tipoIncentivoId: 2, nombre: 'Calculo' }],
      selectedSegmento: [],
      selectedCanales: [],
      selectedCortes: [],
      listaCanal: [],
      listaSegmentos: [],
      listaCortes: [],
      selectedTienda: [],
      selectedIncentivo: [],
      selectedPuesto: "",
      selectedEmpleado: [],
      selectedAnio: (new Date()).getFullYear(),
      listaAnios: [],
      mesesAnteriores: 3,
      tipoIncentivo: 0,
      listIncentivoKey: 1,
      listTienda: [],
      listIncentivo: [],
      dataIncentivo: [],
      chartDataIncentivo: [],
      chartDataIncentivoSum: [],
      chartDataIncentivoIndividual: [],
      chartDataYtd: [],
      chartIncentivo: null,
      chartIncentivoSum: null,
      chartIncentivoIndividual: null,
      chartYtd: null,
      corteChange: null,
      chartGroupingType: 0,
      chartGroupingTypeData: [
        {
          Nombre: "Canal",
          Id: 0
        },
        {
          Nombre: "Corte",
          Id: 1
        },
        {
          Nombre: "Tienda",
          Id: 2
        },
        {
          Nombre: "Segmento",
          Id: 3
        },
        {
          Nombre: "Empleado",
          Id: 4
        },
        {
          Nombre: "Incentivo",
          Id: 5
        },
      ],

      breadcrumb: [
        {
          text: "Dashboard",
          disabled: true,
        },
      ],
    };
  },
  computed: {
    listSegmento() {
      return [...this.$store.getters.user.segmento, ...this.listaSegmentos];
    },
    isChartYtdEmpty() {
      return this.chartDataYtd.length === 0;
    },
    isChartIncentivoEmpty() {
      return this.chartDataIncentivo.length === 0;
    },
    isChartIncentivoSumEmpty() {
      return this.chartDataIncentivoSum.length === 0;
    },
    isChartIncentivoIndividualEmpty() {
      return this.chartDataIncentivoIndividual.length === 0;
    },
    currentUser() {
      return this.$store.getters.user;
    },
    currentcorte: {
      get() {
        return this.$store.state.user.corteId;
      }
    },
    currentUserCapitalLetter() {
      return helper.capitalCase(this.currentUser.nombre);
    },
    listPuesto() {
      return this.currentUser.seleccion.empleados.map((c) => c.Puesto);
    },
    filterEmpleado() {
      return this.currentUser.seleccion.empleados.filter(
        (c) =>
          (this.selectedPuesto.length == 0 ||
            c.Puesto === this.selectedPuesto) &&
          (this.listTienda.length == 0 ||
            this.listTienda
              .map((c) => c.CanalSucursalId)
              .includes(c.CanalSucursalID))
      );
    },
  },
  methods: {
    ...mapActions(["setError", "setErrorMessage", "setMessage"]),

    getAnios() {

      const periodos = this.$store.getters.periodos.map(a => a.FechaFin.split('-')[0])

      const years = periodos.filter((value, index, array) => {
        return array.indexOf(value) === index;
      });

      this.selectedAnio = (new Date()).getFullYear();

      this.listaAnios = years.map(a => ({ Value: parseInt(a), Nombre: a }))
    },

    getGestionado() {

      const empleadoId = this.currentUser.empleadoid === null ? 0 : this.currentUser.empleadoid;

      apiEmpleado
        .getGestionado(empleadoId)
        .then((response) => {
          this.gestionados = response.data;

          this.listaCanal = [...new Set(response.data.map((c) => ({ CanalId: c.CanalID, Nombre: c.Canal })))];

        });
    },

    getCortes() {

      const canales = this.selectedCanales.length === 0 ? this.listaCanal.map(a => a.CanalId) : this.selectedCanales;

      apiEmpleado
        .getCortesPorCanales(canales)
        .then((response) => {
          this.listaCortes = response.data;
        });
    },

    getSegmentos() {

      const canales = this.selectedCanales.length === 0 ? this.listaCanal.map(a => a.CanalId) : this.selectedCanales;

      const actual = this.$store.getters.user.segmento;

      apiEmpleado.getSegmento(canales).then((response) => {


        this.listaSegmentos = response.data.filter(function (item) {

          const index = actual.findIndex(a => a.SegmentoID == item.SegmentoID);

          return index < 0;
        });

      });

    },

    // Generacion Grafico

    generateChartYtd(dataParam) {
      if (this.chartYtd) {
        this.chartYtd.dispose();
      }
      try {
        let chart = am4core.create(
          this.$refs.ytd_incentivo,
          am4charts.XYChart
        );
        chart.colors.step = 3;
        chart.legend = new am4charts.Legend();

        chart.exporting.menu = new am4core.ExportMenu();
        chart.numberFormatter.numberFormat = "$#,###.##";
        chart.scrollbarX = new am4charts.XYChartScrollbar();

        let data = [];
        let componentes = [...new Set(dataParam.map((c) => c.Target))];
        let cortes = [...new Set(dataParam.map((c) => c.Corte))];

        componentes.forEach((d) => {
          let rows = dataParam.filter((f) => f.Target === d);
          let newRow = {
            Target: d,
            Total: 0,
          };
          let total = 0;
          cortes.forEach((c) => {
            let dc = rows.filter((f) => f.Corte === c && f.Target === d);
            if (dc[0]) {
              total += dc[0].Monto;
            }
          });
          newRow[d] = total;
          newRow["Total"] = total;
          data.push(newRow);
        });

        chart.data = data;

        var groupBy = this.chartGroupingTypeData.find(a => a.Id === this.chartGroupingType);
        var groupByName = !groupBy ? "Agrupado" : groupBy.Nombre;

        var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
        categoryAxis.dataFields.category = "Target";
        categoryAxis.title.text = groupByName;
        categoryAxis.renderer.grid.template.location = 0;
        categoryAxis.renderer.labels.template.disabled = true;

        let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
        valueAxis.renderer.minWidth = 35;

        componentes.forEach((c) => {
          let series = chart.series.push(new am4charts.ColumnSeries());
          series.dataFields.categoryX = "Target";
          series.dataFields.valueY = c;
          series.name = c;
          series.stacked = true;
          series.columns.template.width = am4core.percent(80);
          series.columns.template.tooltipText = "{name}: [bold]{valueY}[/]";
        });

        this.chartYtd = chart;

      } catch (e) {
        // empty
      }
    },

    generateChartIncentivo(dataParam, groupByName) {
      if (this.chartIncentivo) {
        this.chartIncentivo.dispose();
      }
      try {
        let chart = am4core.create(
          this.$refs.amchart_incentivo,
          am4charts.XYChart
        );
        chart.colors.step = 3;
        chart.legend = new am4charts.Legend();
        chart.exporting.menu = new am4core.ExportMenu();
        chart.numberFormatter.numberFormat = "$#,###.##";

        let data = [];
        let groupBy = [...new Set(dataParam.map((c) => c.Nombre))];

        groupBy.forEach((d) => {
          let rows = dataParam.filter((f) => f.Nombre === d);
          let newRow = {
            Nombre: helper.capitalCase(d),
            Total: 0,
            MejorResultado: 0,
            ValorPromedio: 0,
            Variable: 0,
          };
          let total = 0;
          rows.forEach((c) => {
            if (c) {
              total += c.Monto;
            }
            newRow.MejorResultado = c.MejorResultado;
            newRow.ValorPromedio = c.ValorPromedio;
            newRow.Variable = c.Variable;
          });
          newRow["Total"] = total;
          data.push(newRow);
        });

        chart.data = data;

        var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
        categoryAxis.dataFields.category = "Nombre";
        categoryAxis.title.text = "Incentivo";
        categoryAxis.renderer.grid.template.location = 0;
        let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
        valueAxis.renderer.minWidth = 35;

        let series = chart.series.push(new am4charts.ColumnSeries());
        series.dataFields.categoryX = "Nombre";
        series.dataFields.valueY = "Total";
        series.name = groupByName;
        series.stacked = true;
        series.columns.template.width = am4core.percent(80);
        series.columns.template.tooltipText = "{categoryX}: [bold]{valueY}[/]";

        let lineSeriesVariable = chart.series.push(new am4charts.LineSeries());
        lineSeriesVariable.dataFields.categoryX = "Nombre";
        lineSeriesVariable.dataFields.valueY = "Variable";
        lineSeriesVariable.name = "Variable";
        lineSeriesVariable.strokeWidth = 3;
        var circleBulletVariable = lineSeriesVariable.bullets.push(
          new am4charts.CircleBullet()
        );
        circleBulletVariable.tooltipText = "{name}: [bold]{Variable}[/]";

        let lineSeriesMaximo = chart.series.push(new am4charts.LineSeries());
        lineSeriesMaximo.dataFields.categoryX = "Nombre";
        lineSeriesMaximo.dataFields.valueY = "MejorResultado";
        lineSeriesMaximo.name = "Mejor resultado";
        lineSeriesMaximo.strokeWidth = 3;
        var circleBulletMaximo = lineSeriesMaximo.bullets.push(
          new am4charts.CircleBullet()
        );
        circleBulletMaximo.tooltipText = "{name}: [bold]{MejorResultado}[/]";

        let lineSeriesPromedio = chart.series.push(new am4charts.LineSeries());
        lineSeriesPromedio.dataFields.categoryX = "Nombre";
        lineSeriesPromedio.dataFields.valueY = "ValorPromedio";
        lineSeriesPromedio.name = "Promedio";
        lineSeriesPromedio.strokeWidth = 3;
        var circleBulletPromedio = lineSeriesPromedio.bullets.push(
          new am4charts.CircleBullet()
        );
        circleBulletPromedio.tooltipText = "{name}: [bold]{ValorPromedio}[/]";

        this.chartIncentivo = chart;
      } catch (e) {
        // empty
      }
    },
    generateChartIncentivoSum(dataParam, groupByName) {
      if (this.chartIncentivoSum) {
        this.chartIncentivoSum.dispose();
      }
      try {
        let chart = am4core.create(
          this.$refs.amchart_incentivo_sum,
          am4charts.XYChart
        );
        chart.colors.step = 2;
        chart.legend = new am4charts.Legend();
        chart.exporting.menu = new am4core.ExportMenu();
        chart.numberFormatter.numberFormat = "$#,###.##";
        chart.scrollbarX = new am4charts.XYChartScrollbar();

        let data = [];
        let componentes = [...new Set(dataParam.map((c) => c.Incentivo))];
        let groupBy = [...new Set(dataParam.map((c) => c.Nombre))];

        groupBy.forEach((d) => {
          let rows = dataParam.filter((f) => f.Nombre === d);
          let newRow = {
            Nombre: helper.capitalCase(d),
            Total: 0,
          };
          let total = 0;
          componentes.forEach((c) => {
            let dc = rows.filter((f) => f.Nombre === d && f.Incentivo === c);
            if (dc[0]) {
              newRow[c] = dc[0].Monto;
              total += dc[0].Monto;
            }
          });
          newRow["Total"] = total;
          data.push(newRow);
        });

        chart.data = data;

        var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
        categoryAxis.dataFields.category = "Nombre";
        categoryAxis.title.text = groupByName;
        categoryAxis.renderer.grid.template.location = 0;
        let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
        valueAxis.renderer.minWidth = 35;

        componentes.forEach((c) => {
          let series = chart.series.push(new am4charts.ColumnSeries());
          series.dataFields.categoryX = "Nombre";
          series.dataFields.valueY = c;
          series.name = c;
          series.stacked = true;
          series.columns.template.width = am4core.percent(80);
          series.columns.template.tooltipText = "{Nombre}\n{name}: [bold]{valueY}[/]";
        });

        let lineSeries = chart.series.push(new am4charts.LineSeries());
        lineSeries.dataFields.categoryX = "Nombre";
        lineSeries.dataFields.valueY = "Total";
        lineSeries.name = "Total";
        lineSeries.strokeWidth = 3;
        var circleBullet = lineSeries.bullets.push(
          new am4charts.CircleBullet()
        );
        circleBullet.circle.stroke = am4core.color("#fff");
        circleBullet.tooltipText = "{Nombre}\n{name}: [bold]{valueY}[/]";

        this.chartIncentivoSum = chart;
      } catch (e) {
        // empty
      }
    },
    generateChartIncentivoIndividual(dataParam) {
      if (this.chartIncentivoIndividual) {
        this.chartIncentivoIndividual.dispose();
      }
      try {
        let chart = am4core.create(
          this.$refs.amchart_incentivo_individual,
          am4charts.XYChart
        );
        chart.colors.step = 3;
        chart.legend = new am4charts.Legend();

        chart.exporting.menu = new am4core.ExportMenu();
        chart.numberFormatter.numberFormat = "$#,###.##";

        let data = [];
        let componentes = [...new Set(dataParam.map((c) => c.Incentivos))];
        let cortes = [...new Set(dataParam.map((c) => c.Corte))];

        cortes.forEach((d) => {
          let rows = dataParam.filter((f) => f.Corte === d);
          let newRow = {
            Corte: d,
            Total: 0,
            Promedio: rows.length > 0 ? rows[0].Promedio : 0,
            Promedio2: rows.length > 0 ? rows[0].Promedio2 : 0 
          };
          let total = 0;

          componentes.forEach((c) => {
            let dc = rows.filter((f) => f.Corte === d && f.Incentivos === c);
            if (dc[0]) {
              newRow[c] = dc[0].Monto;
              total += dc[0].Monto;
            }
          });

          newRow["Total"] = total;
          data.push(newRow);
        });

        chart.data = data;

        var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
        categoryAxis.dataFields.category = "Corte";
        categoryAxis.title.text = "Corte";
        categoryAxis.renderer.grid.template.location = 0;

        let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
        valueAxis.renderer.minWidth = 35;

        componentes.forEach((c) => {
          let series = chart.series.push(new am4charts.ColumnSeries());
          series.dataFields.categoryX = "Corte";
          series.dataFields.valueY = c;
          series.name = c;
          series.stacked = true;
          series.columns.template.width = am4core.percent(80);
          series.columns.template.tooltipText = "{name}: [bold]{valueY}[/]";
        });

        let lineSeries = chart.series.push(new am4charts.LineSeries());
        lineSeries.dataFields.categoryX = "Corte";
        lineSeries.dataFields.valueY = "Total";
        lineSeries.name = "Total";
        lineSeries.strokeWidth = 3;
        var circleBullet = lineSeries.bullets.push(
          new am4charts.CircleBullet()
        );
        circleBullet.circle.stroke = am4core.color("#fff");
        circleBullet.tooltipText = "{name}: [bold]{valueY}[/]";

        //Promedio

        let lineSeriesPromedio = chart.series.push(new am4charts.LineSeries());
        lineSeriesPromedio.dataFields.categoryX = "Corte";
        lineSeriesPromedio.dataFields.valueY = "Promedio";
        lineSeriesPromedio.name = "Promedio Canal";
        lineSeriesPromedio.strokeWidth = 3;
        var circleBulletPromedio = lineSeriesPromedio.bullets.push(
          new am4charts.CircleBullet()
        );
        circleBulletPromedio.circle.stroke = am4core.color("#fff");
        circleBulletPromedio.tooltipText = "{name}: [bold]{valueY}[/]";


        //Promedio

        let lineSeriesPromedio2 = chart.series.push(new am4charts.LineSeries());
        lineSeriesPromedio2.dataFields.categoryX = "Corte";
        lineSeriesPromedio2.dataFields.valueY = "Promedio2";
        lineSeriesPromedio2.name = "Promedio Tienda";
        lineSeriesPromedio2.strokeWidth = 3;
        var circleBulletPromedio2 = lineSeriesPromedio2.bullets.push(
          new am4charts.CircleBullet()
        );
        circleBulletPromedio2.circle.stroke = am4core.color("#fff");
        circleBulletPromedio2.tooltipText = "{name}: [bold]{valueY}[/]";



        this.chartIncentivoIndividual = chart;
      } catch (e) {
        // empty
      }
    },
    // Consulta Informacion
    getIncentivoOptions(param) {

      const canales = this.selectedCanales.length === 0 ? this.listaCanal.map(a => a.CanalId) : this.selectedCanales;

      if (!param) {
        param = {
          canalId: [...Array.from(this.currentUser.seleccion.canales), ...canales],
          corte: this.currentUser.corte,
          corteId: this.currentUser.corteId,
          gruposComision: this.selectedPuesto.length === 0 ? [] : [this.selectedPuesto],
          empleado: Array.from(
            this.currentUser.seleccion.empleados.map((c) => c.EmpleadosId)
          ),
        };
      } else {
        param.canalId = [...Array.from(this.currentUser.seleccion.canales), ...canales];
      }


      api.getChartEmpleadoIncentivoOptions(param).then((response) => {
        this.listIncentivo = response.data;
        this.listIncentivoKey += 1;
      });
    },
    // Refrescar
    refreshChart() {
      if (this.currentUser.corteId > 0) {
        this.processing2 = true;
        var param = {
          canalId: Array.from(this.currentUser.seleccion.canales),
          corte: this.currentUser.corte,
          corteId: this.currentUser.corteId,
          gruposComision: this.selectedPuesto.length === 0 ? [] : [this.selectedPuesto],
          empleado: Array.from(
            this.currentUser.seleccion.empleados.map((c) => c.EmpleadosId)
          ),
          // segmento: Array.from(this.selectedSegmento),
          // sucursal: Array.from(this.selectedTienda),
        };
        this.getIncentivoOptions(param);
        api.getChartEmpleado(param).then((response) => {
          this.dataIncentivo = response.data;
          this.processing2 = false;
        });
      } else {
        let mensaje = {
          title: "Dashboards",
          body: "Favor seleccionar un corte para ver graficos",
          type: "success",
        };
        this.setMessage(mensaje);
      }
    },
    generateDataChartIncentivo() {
      // if (this.selectedEmpleado.length === 0) return;
      let groupBy =
        this.selectedEmpleado.length > 0
          ? "EmpleadosId"
          : this.selectedTienda.length > 0
            ? "CanalSucursalID"
            : this.selectedSegmento.length > 0
              ? "SegmentoID"
              : "";

      let groupByName =
        this.selectedEmpleado.length > 0
          ? "Empleado"
          : this.selectedTienda.length > 0
            ? "Tienda"
            : this.selectedSegmento.length > 0
              ? "Segmento"
              : "";

      let ids =
        this.selectedEmpleado.length > 0
          ? this.selectedEmpleado
          : this.selectedTienda.length > 0
            ? this.selectedTienda
            : this.selectedSegmento.length > 0
              ? this.selectedSegmento
              : [];

      this.chartDataIncentivo = this.dataIncentivo.filter(
        (c) =>
          ids.includes(c[groupBy]) &&
          this.selectedIncentivo.includes(c.IncentivoId)
      );

      var sumarizado = helper.sumBy(
        this.dataIncentivo.filter(
          (c) => this.selectedIncentivo.includes(c.IncentivoId)
        ),
        groupByName,
        "Monto"
      );

      var mejorResultado = helper.maxBy(sumarizado, groupByName, "Monto");
      var valorPromedio = helper.aveBy(sumarizado, groupByName, "Monto");

      this.chartDataIncentivo.forEach((item) => {
        item.Nombre = helper.abbreviateName(item[groupByName]);
        item.MejorResultado = mejorResultado;
        item.ValorPromedio = valorPromedio;
      });

      this.generateChartIncentivo(this.chartDataIncentivo, groupByName);
    },
    generateDataChartIncentivoSum() {
      if (!this.selectedEmpleado || this.selectedEmpleado.length === 0) return;

      let groupBy =
        this.selectedEmpleado.length > 0
          ? "EmpleadosId"
          : this.selectedTienda.length > 0
            ? "CanalSucursalID"
            : this.selectedSegmento.length > 0
              ? "SegmentoID"
              : "";

      let groupByName =
        this.selectedEmpleado.length > 0
          ? "Empleado"
          : this.selectedTienda.length > 0
            ? "Tienda"
            : this.selectedSegmento.length > 0
              ? "Segmento"
              : "";

      let ids =
        this.selectedEmpleado.length > 0
          ? this.selectedEmpleado
          : this.selectedTienda.length > 0
            ? this.selectedTienda
            : this.selectedSegmento.length > 0
              ? this.selectedSegmento
              : [];

      this.chartDataIncentivoSum = this.dataIncentivo.filter(
        (c) =>
          ids.includes(c[groupBy]) &&
          this.filterEmpleado.some((e) => e.EmpleadosId === c.EmpleadosId)
      );

      this.chartDataIncentivoSum.forEach((item) => {
        item.Incentivo = helper.replacePuesto(item.Incentivo);
        item.Nombre = helper.abbreviateName(item[groupByName]);
      });

      this.generateChartIncentivoSum(this.chartDataIncentivoSum, groupByName);
    },
    generateDataChartIncentivoIndividual() {
      this.processing1 = true;
      var param = {
        corte: this.currentUser.corte,
        corteId: this.currentUser.corteId,
        EmpleadoId: this.$store.getters.user.empleadoid,
        MesesAnteriores: this.mesesAnteriores,
        TipoIncentivo: this.tipoIncentivo
      };

      api.getChartEmpleadoIndividual(param).then((response) => {

        this.chartDataIncentivoIndividual = response.data;

        // this.chartDataIncentivoIndividual.forEach((item) => {
        //   item.Corte = helper.getName(
        //     this.$store.getters.cortes,
        //     item.CorteID,
        //     "CorteId",
        //     "Nombre"
        //   );
        // });
        this.generateChartIncentivoIndividual(
          this.chartDataIncentivoIndividual
        );

        this.processing1 = false;
      });
    },

    generateDataChartYtd() {
      this.processingYtd = true;


      var param = {
        corte: this.currentUser.corte,
        corteId: this.currentUser.corteId,
        EmpleadoId: this.$store.getters.user.empleadoid,
        MesesAnteriores: this.mesesAnteriores,
        TipoIncentivo: this.tipoIncentivo,
        ChartGroupingType: this.chartGroupingType,
        CanalId: this.selectedCanales.length === 0 ? this.listaCanal.map(a => a.CanalId) : this.selectedCanales,
        Segmento: this.selectedSegmento.length === 0 ? [] : this.selectedSegmento,
        Incentivos: this.selectedIncentivo.length === 0 ? [] : this.selectedIncentivo,
        Cortes: this.selectedCortes.length === 0 ? [] : this.selectedCortes,
        Anio: this.selectedAnio
      };

      api.getChartYtd(param).then((response) => {

        this.chartDataYtd = response.data;

        this.generateChartYtd(this.chartDataYtd)

        this.processingYtd = false;
      });
    },
    generate() {

      if (this.currentUser.perfil === 'Gerente') {
        this.generateDataChartIncentivoSum();
        this.generateDataChartIncentivo();
      }

      this.generateDataChartIncentivoIndividual();
      this.generateDataChartYtd();
    },
  },
  watch: {
    chartGroupingType: function () {
      this.generateDataChartYtd();
    },
    selectedCanales: function () {

      this.getSegmentos();
      this.getIncentivoOptions();
      this.getCortes();

    },
    selectedSegmento: function () {
      this.listTienda = [];
      var list = this.listSegmento
        .filter((c) => this.selectedSegmento.includes(c.SegmentoID))
        .map((c) => c.CanalAsociadoSucursales);

      list.forEach((c) => {
        if (c.length > 0) {
          c.forEach((d) => {
            this.listTienda.push(d);
          });
        }
      });
    },
    selectedPuesto: function () {
      this.selectedEmpleado = [];

      if (this.selectedPuesto === null) {
        this.selectedPuesto = "";

        return;
      }

      var param = {
        canalId: Array.from(this.currentUser.seleccion.canales),
        corte: this.currentUser.corte,
        corteId: this.currentUser.corteId,
        gruposComision: !this.selectedPuesto || this.selectedPuesto === null || this.selectedPuesto?.length === 0 ? [] : [this.selectedPuesto],
        empleado: Array.from(
          this.currentUser.seleccion.empleados.map((c) => c.EmpleadosId)
        ),
      };

      this.getIncentivoOptions(param);
    },
    selectedEmpleado: function () {

    },
    '$store.state.updateCorte': function () {
      this.refreshChart();
      this.generateDataChartIncentivoIndividual();
    }
  },
  mounted() {

    // this.$store.dispatch("setBreadcrumbs", this.breadcrumb);

    if (this.currentUser.seleccion == undefined) {
      this.setMessage(
        "Primero debe seleccionar el corte para navegar en el sistema"
      );
    } else {
      this.getGestionado();
      this.getCortes();
      this.refreshChart();
      this.getAnios();
      this.generateDataChartIncentivoIndividual();
    }
  },
  beforeDestroy() {
    if (this.chartam) {
      this.chartam.dispose();
    }
    if (this.chartIncentivo) {
      this.chartIncentivo.dispose();
    }
  }
};
</script>

<style scope>
.v-alert.v-sheet.theme--dark.v-alert--dense.warning {
  margin-bottom: 0;
}

.col-md-12.col-12 {
  padding: 0;
}
</style>